<template>
  <div class="medication-table">
    <div class="list-wrap">
      <MedicalItem
        v-for="item in selfReportedMedication"
        :key="item.id"
        :item="item"
        :name="item.name"
        :quantity="item.quantity"
      />
    </div>
    <div class="edit-wrap">
      <v-autocomplete
        v-model="name"
        :errors="nameErrors"
        :items="drugs"
        :label="$t('visits.medical.medicationPlaceholder')"
        :loading="isLoadingDrugs"
        :placeholder="$t('visits.medical.medicationPlaceholder')"
        :search-input.sync="drugSearch"
        cache-items
        className="bd-grey"
        clearable
        fieldName="name"
        hide-details
        hide-no-data
        solo
        @change="onSelectMedication"
      ></v-autocomplete>
    </div>
  </div>
</template>
<script>
import orderBy from "lodash/orderBy";
import { mapActions, mapState } from "pinia";
import { required } from "vuelidate/lib/validators";

import { DosespotApi } from "@/api/dosespot";
import MedicalItem from "@/components/Medications/MedicationlItem";
import { useAutoSaveStore } from "@/pinia-store/autoSave";
import { usePatientStore } from "@/pinia-store/patient";

export default {
  name: "MedicationTable",
  props: ["place"],
  components: {
    MedicalItem,
  },
  data: () => ({
    name: "",
    frequencyList: [],
    drugs: [],
    drugsSearchResult: [],
    drugSearch: null,
    isLoadingDrugs: false,
    hideDosageFields: true,
    selectedItem: {},
  }),
  validations: {
    name: { required },
    startDate: { required },
  },
  watch: {
    drugSearch(val) {
      if (!val || val === this.name || val?.length < 3) return;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.isLoadingDrugs = true;
        DosespotApi.searchMedication(val).then((values) => {
          this.isLoadingDrugs = false;
          this.drugsSearchResult = values;
          const newValues = [...values.map((value) => value.name)];
          this.drugs = orderBy(newValues, [(name) => name.toLowerCase()], ["asc"]);
        });
      }, 300);
    },
  },
  computed: {
    ...mapState(usePatientStore, ["selfReportedMedication"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      const field = this.$t("visits.medical.name");
      !this.$v.name.required && errors.push(this.$t("validation.required", { field }));
      return errors;
    },
  },

  methods: {
    ...mapActions(useAutoSaveStore, ["startSave", "endSave"]),
    ...mapActions(usePatientStore, ["getSelfReportedMedication", "addSelfReportedMedication"]),

    async onSelectMedication(selectedValue) {
      const item = this.drugsSearchResult.find((result) => result.name === selectedValue);
      if (item) {
        this.startSave();
        await this.addSelfReportedMedication(item);
        this.name = "";
        this.endSave();
        await this.getSelfReportedMedication();
        this.selectedItem = item;
      }
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },
  },
  mounted() {
    const medicationFrequency = this.$t("general.medicationFrequency");
    if (medicationFrequency) {
      this.frequencyList = Object.keys(medicationFrequency).map((key) => medicationFrequency[key]);
    }
  },
};
</script>
<style lang="scss">
.medication-table {
  .v-input__slot {
    border: 1px solid rgba(51, 52, 62, 0.4) !important;
  }

  .list-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__search {
    padding-top: 0;
  }

  &__title {
    font-weight: bold;
    padding: 20px 20px 0 20px;
    color: $primaryblack2;
  }

  .edit-wrap {
    display: flex;
    align-items: center;
    gap: 10px;

    .v-input__slot {
      margin-top: 0 !important;
    }

    .add-medication {
      background-color: #fff !important;
      flex-basis: 200px;
    }
  }
}
</style>
